<!--
  需求发布成功
    + 推荐的是服务列表
    + 根据发布需求时选择的二级服务类型而来做相同推荐
 -->
<template>
  <div class="detail" v-loading="loading" >
    <detail-header title="服务发布成功" />
    <div class="detail_main page_width">
      <div class="success_box">
        <img class="success_img" src="../../../assets/images/create_require_success.png" alt=""/>
        <p class="success_tips tw-text-16px tw-font-bold">服务发布成功</p>
      </div>
      <div class="recommend_box">
        <div class="recommend_box_header">
          <div class="line"></div>
          <h1 class="">相关需求产品推荐</h1>
        </div>
        <div class="recommend_box_main">
          <div
            class="recommend_item"
            v-for="item in requireList"
            :key="item.id"
            @click="onRequireDetail(item)">
            <h1 :title="item.title">{{ item.title }}</h1>
            <div class="item_info">
              <img v-if="item.avatarUrl" :src="item.avatarUrl" alt=""/>
              <img v-else src="../../../assets/images/default_avatar.png" alt=""/>
              <p>{{ item.organizationName }}</p>
            </div>
            <p class="item_des" :title="item.description">{{ item.description }}</p>
          </div>
        </div>
      </div>
      <div class="recommend_box">
        <div class="recommend_box_header">
          <div class="line"></div>
          <h1 class="">相关资源推荐</h1>
        </div>
        <div class="recommend_box_main">
          <div
            class="recommend_item"
            v-for="item in patentList"
            :key="item.id"
            @click="onPatentDetail(item)">
            <h1 :title="item.title">{{ item.title }}</h1>
            <div class="item_info">
              <p>{{ item.type }}</p>
            </div>
            <p class="item_des" :title="item.description">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      loading: false,
      // 需求列表
      requireList: [],
      // 专利匹配列表
      patentList: []
    }
  },
  computed: {
    // 服务id
    serviceTypeId () {
      return this.$route.query.serviceTypeId
    },
    title () {
      return this.$route.query.title || ''
    },
    content () {
      return this.$route.query.content || ''
    },
    keywords () {
      return this.$route.query.keywords || ''
    }
  },
  created () {
    this.getRecommendRequireList()
    this.getPatentMatchList()
  },
  methods: {
    // 获取需求推荐列表
    getRecommendRequireList () {
      this.loading = true
      api.getRecommendRequireList({
        serviceTypeId: this.serviceTypeId
      }).then(res => {
        if (res.data.code === 0) {
          console.log('需求推荐列表', res.data.data)
          this.requireList = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取服务推荐列表
    getPatentMatchList () {
      this.loading = true
      api.getPatentMatchList({
        title: this.title,
        content: this.content,
        keywords: this.keywords
      }).then(res => {
        if (res.data.code === 0) {
          console.log('专利匹配', res.data.data)
          const list = res.data.data.list
          this.patentList = list.slice(0, 6)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },

    // 进入需求详情
    onRequireDetail (item) {
      window.open(`/require-detail/${item.id}?from=recommend`)
    },
    // 进入专利详情
    onPatentDetail (item) {
      const { key } = item
      let keywords = item.title
      if (key) {
        const keyList = key.split('%')
        keywords = keyList.length > 0 ? keyList[0] : keywords
      }
      window.open(`/patent-detail/${item.id}?type=allContent&keywords=${keywords}`)
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail {
    padding-bottom: 40px;
    .success_box {
      text-align: center;
      margin-bottom: 30px;
      & > img {
        margin-top: 40px;
      }
      .success_tips {
        color: #6AC244;
        text-align: center;
        line-height: 22px;
        padding: 9px 0;
      }
    }
    .recommend_box {
      text-align: left;
      .recommend_box_header {
        display: flex;
        align-items: center;
        padding: 0 20px;
        width: 1200px;
        height: 48px;
        background: #F7F8F9;
        border-radius: 4px;
        .line {
          width: 3px;
          height: 20px;
          background: #3473E6;
          border-radius: 1px;
        }
        & > h1 {
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          color: #303133;
          margin-left: 10px;
        }
      }
      .recommend_box_main {
        display: flex;
        flex-wrap: wrap;
        .recommend_item {
          width: 389px;
          height: 152px;
          background: #FFFFFF;
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          padding: 20px;
          margin: 20px 16px 0 0;
          cursor: pointer;
          transition: all 0.3s ease;
          &:nth-child(3n) {
            margin-right: 0;
          }
          & > h1 {
            font-size: 16px;
            font-weight: bold;
            line-height: 22px;
            color: #303133;
            margin-bottom: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .item_info {
            display: flex;
            margin-bottom: 14px;
            & > img {
              width: 20px;
              height: 20px;
              object-fit: cover;
              margin-right: 10px;
            }
            & > p {
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #3473E6;
            }
          }
          .item_des {
            height: 40px;
            font-size: 14px;
            line-height: 20px;
            color: #909399;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          &:hover {
            border-color: #3473E6;
          }
        }
      }
      &+.recommend_box {
        margin-top: 40px;
      }
    }
  }
</style>
